// import React from 'react';
// import './WhyCodexodus.css'; // Assuming we will style it separately

// const WhyCodexodus = () => {
//     return (
//         <div className="why-codexodus">
//             <h2>Why Choose Codexodus for Your Coaching Centre?</h2>
//             <div className="benefits-container">
//                 <div className="column left">
//                     <div className="benefitt">
//                         <h3>Higher Revenue through Student Growth</h3>
//                         <p>Attract more students with codeXodus, leading to higher revenue and growth.</p>
//                     </div>
//                     <div className="benefitt">
//                         <h3>Upsell Advanced Courses</h3>
//                         <p>Offer advanced, interview prep courses at higher rates for increased profitability.</p>
//                     </div>
//                     <div className="benefitt">
//                         <h3>Automated Learning = Reduced Operational Costs</h3>
//                         <p>CodeXodus's AI reduces instructor dependency, enabling larger student batches with fewer staff.</p>
//                     </div>
//                 </div>

//                 <div className="separator"></div>

//                 <div className="column right">
//                     <div className="benefitt">
//                         <h3>Monetize Certification and Testing</h3>
//                         <p>Earn from proctored exams, certifications, and skill assessments as additional revenue streams.</p>
//                     </div>
//                     <div className="benefitt">
//                         <h3>Higher Retention</h3>
//                         <p>Gamified learning boosts engagement and reduces dropouts, ensuring steady income.</p>
//                     </div>
//                     <div className="benefitt">
//                         <h3>Corporate Clients</h3>
//                         <p>Offer tailored training to businesses, creating high-value corporate partnerships.</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default WhyCodexodus;

import React, { useEffect } from 'react';
import './WhyCodexodus.css'; // Assuming we will style it separately
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const WhyCodexodus = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,  // Duration of the animation
            easing: 'ease-in-out', // Easing function for animation
            once: true, // Trigger animation only once
        });
    }, []);

    return (
        <div className="why-codexodus">
            <h2 data-aos="fade-up">Boost Your Revenue with CodeXodus</h2>
            <div className="benefits-container">
                <div className="column left">
                    <div className="benefitt" data-aos="fade-right">
                        <h3>Drive Student Growth for Higher Revenue</h3>
                        <p>Attract more students with CodeXodus’s advanced features, helping you grow your business and revenue effortlessly.</p>
                    </div>
                    <div className="benefitt" data-aos="fade-right">
                        <h3>Upsell Premium Courses</h3>
                        <p>Offer advanced and interview preparation courses at premium rates, maximizing profitability and student value.</p>
                    </div>
                    <div className="benefitt" data-aos="fade-right">
                        <h3>Attract a Broader Audience with Regional Language Support</h3>
                        <p>Expand your reach by offering programming courses in multiple regional languages, making coding accessible to non-English medium learners and gaining their trust.</p>
                    </div>
                </div>

                <div className="separator"></div>

                <div className="column right">
                    <div className="benefitt" data-aos="fade-left">
                        <h3>Monetize Certifications and Testing</h3>
                        <p>Generate additional income through proctored exams, skill-based certifications, and performance assessments.</p>
                    </div>
                    <div className="benefitt" data-aos="fade-left">
                        <h3>Increase Retention with Gamified Learning</h3>
                        <p>Engage students with badges, leaderboards, and gamified challenges, keeping them motivated and reducing dropouts.</p>
                    </div>
                    <div className="benefitt" data-aos="fade-left">
                        <h3>Expand into Corporate Training</h3>
                        <p>Offer customized programs to businesses, unlocking high-value corporate partnerships and diversified income streams.</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default WhyCodexodus;
