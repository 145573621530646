// import React from 'react';
// import './Features.css';

// function Features() {
//     const features = [
//         { id: 1, text: "Cutting-Edge Coding Platform" },
//         { id: 2, text: "AI-Powered Assistance and Instant Feedback" },
//         { id: 3, text: "Game-Based and Interactive Learning" },
//         { id: 4, text: "Regional Language Support" },
//         { id: 5, text: "Enhanced Student Development and Career Readiness" },
//         { id: 6, text: "24/7 Accessibility and Technical Support" },
//     ];

//     return (
//         <div className="features-container">
//             <h2>Features at a glance</h2>
//             <div className="features-gridd">
//                 {features.map((feature) => (
//                     <div className="feature-item" key={feature.id}>
//                         <div className="feature-circle">
//                             <span>{feature.id}</span>
//                         </div>
//                         <p>{feature.text}</p>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default Features;


import React, { useEffect } from 'react';
import './Features.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

function Features() {
    const features = [
        { id: 1, text: "Attract Students, Professionals, and Colleges for Programming Coaching or Placement Training" },
        { id: 2, text: "Keep Students Engaged and Retain Their Interest in Coding" },
        { id: 3, text: "Demonstrate Tangible Learning Outcomes or Results That Parents and Institutions Can Trust" },
        { id: 4, text: "Provide Hands-On Practice Across Programming Languages" },
        { id: 5, text: "Conduct Secure and Proctored Exams" },
        { id: 6, text: "Scale Your Coaching Business While Reducing Overhead" },
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the animation
            easing: 'ease-in-out', // Easing function
            once: true, // Animations trigger only once
        });
    }, []);

    return (
        <div className="features-container">
            <h2>Are you struggling with one of these?
            </h2>
            <div className="features-gridd">
                {features.map((feature) => (
                    <div
                        className="feature-item"
                        key={feature.id}
                        data-aos="fade-up" // AOS animation
                    >
                        <div className="feature-circle">
                            <span>{feature.id}</span>
                        </div>
                        <p>{feature.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Features;
