// import React from 'react';
// import './PlacementReadiness.css';
// import Image1 from '../Images/p1.svg';
// import Image2 from '../Images/p2.svg';
// import Image3 from '../Images/p3.svg';
// import Image4 from '../Images/p4.svg';

// function PlacementReadiness() {
//     const readinessFeatures = [
//         {
//             title: "Resume Building",
//             description:
//                 "Completing coding challenges and earning certificates on CodeXodus showcases students' coding skills and dedication to learning, helping them stand out to potential employers.",
//             image: Image1,
//         },
//         {
//             title: "Mock Interviews and Challenges",
//             description:
//                 "The platform provides mock coding challenges that simulate real interviews, building students' confidence and readiness for actual interview scenarios.",
//             image: Image2,
//         },
//         {
//             title: "Industry-Relevant Skills",
//             description:
//                 "CodeXodus focuses on industry-relevant skills like code optimization, algorithmic thinking, and debugging, making students more attractive and prepared for tech roles.",
//             image: Image3,
//         },
//         {
//             title: "Interview Preparation",
//             description:
//                 "It prepares students for the types of coding assessments commonly used by companies in their hiring processes.",
//             image: Image4,
//         },
//     ];

//     return (
//         <div className="placement-readiness-container">
//             <h2 className="placement-readiness-title">Placement Readiness for Students</h2>
//             <div className="placement-readiness-content">
//                 <div className="placement-left-section">
//                     <img
//                         src={Image4}
//                         alt="Students Preparing"
//                         className="placement-left-image"
//                     />
//                     <div className="placement-interview-prep">
//                         <h3>Interview Preparation</h3>
//                         <p>
//                             It prepares students for the types of coding assessments commonly used by companies in their hiring processes.
//                         </p>
//                     </div>
//                 </div>
//                 <div className="placement-right-section">
//                     {readinessFeatures.slice(0, 3).map((feature, index) => (
//                         <div className="placement-feature" key={index}>
//                             <img
//                                 src={feature.image}
//                                 alt={feature.title}
//                                 className="placement-feature-image"
//                             />
//                             <div>
//                                 <h4 className="placement-feature-title">{feature.title}</h4>
//                                 <p className="placement-feature-description">{feature.description}</p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default PlacementReadiness;
import React, { useEffect } from 'react';
import './PlacementReadiness.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Image1 from '../Images/p1.svg';
import Image2 from '../Images/p2.svg';
import Image3 from '../Images/p3.svg';
import Image4 from '../Images/p4.svg';

function PlacementReadiness() {
    const readinessFeatures = [
        {
            title: "Expand Your Reach",
            description:
                "CodeXodus helps you attract students, professionals, and colleges, including non-English medium learners, with its regional language support and inclusive features, ensuring coding is accessible to everyone.",
            image: Image2,
        },
        {
            title: "Deliver Results That Matter",
            description:
                "Engage learners with 5,000+ coding challenges, AI-driven personalization, and real-time analytics to showcase tangible outcomes and build trust with students, parents, and institutions.",
            image: Image1,
        },
        {
            title: "Simplify and Scale",
            description:
                "Streamline your operations with secure, proctored exams, flexible learning modes, and a scalable platform that reduces overhead, allowing you to focus on growing your coaching business effortlessly.",
            image: Image3,
        },
        {
            title: "Explore CodeXodus Now | Let’s Collaborate",
            description:
                "It prepares students for the types of coding assessments commonly used by companies in their hiring processes.",
            image: Image4,
        },
    ];

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the animation
            easing: 'ease-in-out', // Easing function
            once: true, // Animation happens only once
        });
    }, []);

    return (
        <div className="placement-readiness-container">
            <h2 className="placement-readiness-title" data-aos="fade-up">
                Empower Your Coaching Business with CodeXodus

            </h2>
            <div className="placement-readiness-content">
                <div className="placement-left-section" data-aos="fade-right">
                    <img
                        src={Image4}
                        alt="Students Preparing"
                        className="placement-left-image"
                    />
                    <div className="placement-interview-prep">
                        <h3>Explore CodeXodus Now
                        </h3>
                        <p>
                            Let’s Collaborate
                        </p>
                    </div>
                </div>
                <div className="placement-right-section">
                    {readinessFeatures.slice(0, 3).map((feature, index) => (
                        <div
                            className="placement-feature"
                            key={index}
                            data-aos="fade-up" // Adding AOS animation for each feature
                        >
                            <img
                                src={feature.image}
                                alt={feature.title}
                                className="placement-feature-image"
                            />
                            <div>
                                <h4 className="placement-feature-title">{feature.title}</h4>
                                <p className="placement-feature-description">{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PlacementReadiness;
