import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from "./Pages/NavBar";
import Footer from './Pages/Footer';
import whatsappIcon from "./Images/whatsapp.svg";
import ContactUs from './Pages/ContactUs';
import Courser from "./Courser";
import useWindowSize from "./useWindowSize";
import Layout from './Pages/Layout';
function App() {
  const { width } = useWindowSize();
  return (
    <Router>
      <div className="App">

        <NavBar />
        {width > 768 && <Courser />}

        <Routes>

          <Route path="/" element={<Layout />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <a
          href="https://wa.me/919894079619"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsappIcon} alt="WhatsApp" />
        </a>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
