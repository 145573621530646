import HeroSection from "./HeroSection";
import Code from "./Code";
import Features from './Features';
import PlacementReadiness from './PlacementReadiness';
import Empower from './Empower';
import Advantages from './Advantage';

import AvailableCourses from './AvailableCourses';
import CodeXodusOffers from './CodeXodusOffers';
import WhyCodexodus from './WhyCodexodus';
import Footer from './Footer';

function App() {

    return (

        <div >


            <HeroSection />
            <Code />
            <Features />
            <PlacementReadiness />
            <Empower />
            <Advantages />
          
            <WhyCodexodus />
            <AvailableCourses />
            <CodeXodusOffers />


            
        </div>

    );
}

export default App;
