// import React from "react";
// import "./Advantage.css";  // Updated import

// function App() {
//     const advantages = [
//         { id: 1, title: "Strong Foundation in Programming" },
//         { id: 2, title: "Enhanced Problem-Solving Skills" },
//         { id: 3, title: "Career Readiness" },
//         { id: 4, title: "Hands-On Experience" },
//         { id: 5, title: "Industry-Relevant Skills" },
//     ];

//     return (
//         <div className="student-advantage">
//             <div className="header">
//                 <h1>codeXodus advantage for students</h1>
//                 <p>
//                     Our codeXodus builds a strong programming foundation and real-world skills.
//                     Students master data structures, algorithms, and problem-solving, becoming
//                     career-ready. It promotes academic excellence, critical thinking, and
//                     essential soft skills, preparing learners for technical interviews and
//                     professional success.
//                 </p>
//             </div>
//             <div className="advantages-grid">
//                 {advantages.map((advantage) => (
//                     <div className="advantage-card" key={advantage.id}>
//                         <span className="badgee">Advantage {advantage.id}</span>
//                         <h2>{advantage.title}</h2>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default App;


import React, { useEffect } from "react";
import "./Advantage.css";  // Updated import
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

function App() {
    const advantages = [
        {
            id: 1,
            title: "Personalized Logins",
            description: "Student, faculty, and admin logins for seamless management",
        },
        {
            id: 2,
            title: "Free Tests and Exams",
            description: "Number of free tests and one free proctored exam per batch",
        },
        {
            id: 3,
            title: "Extensive Course Access",
            description: "6 months of subscription with 100 coding problems per course",
        },
        {
            id: 4,
            title: "Training for Your Team",
            description: "Comprehensive onboarding for you and your team",
        },
        {
            id: 5,
            title: "Reliable Technical Support",
            description: "Continuous support to ensure smooth operations",
        },
    ];


    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the animation
            easing: "ease-in-out", // Easing function
            once: true, // Animation happens only once when the element is visible
        });
    }, []);

    return (
        <div className="student-advantage">
            <div className="header" data-aos="fade-up">
                <h1>What We Offer
                </h1>
                <p>
                    When you partner with CodeXodus, we bring exceptional value to your institution with the following services tailored to your needs.
                </p>
            </div>
            <div className="advantages-grid">
                {advantages.map((advantage) => (
                    <div
                        className="advantage-card"
                        key={advantage.id}
                        data-aos="zoom-in"  // Adding AOS animation to each advantage card
                    >
                        <span className="badgee">OFFER {advantage.id}</span>
                        <h2>{advantage.title}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
