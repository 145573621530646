// import React from "react";
// import "./Empower.css";

// function App() {
//     const features = [
//         {
//             title: "5000+ Coding Problems",
//             description: "Practice in 23 languages from basic to advanced",
//         },
//         {
//             title: "AI Assistance",
//             description: "Personalized guidance and feedback with an AI chatbot",
//         },
//         {
//             title: "Cloud-Based",
//             description: "Accessible on any device, no installation required",
//         },
//         {
//             title: "Multi-Language Support",
//             description: "Learn coding in regional languages.",
//         },
//         {
//             title: "Gamified Learning",
//             description: "Earn points, badges, and join leaderboards",
//         },
//         {
//             title: "Real-World Projects",
//             description: "Hands-on challenges for job and interview prep",
//         },
//     ];

//     return (
//         <div className="empower-app">
//             <h1 className="empower-title">Empower Your Coaching Centre with codeXodus</h1>
//             <div className="empower-features-grid">
//                 {features.map((feature, index) => (
//                     <div className="empower-feature-card" key={index}>
//                         <h2>{feature.title}</h2>
//                         <p>{feature.description}</p>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }

// export default App;
import React, { useEffect } from "react";
import "./Empower.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

function App() {
    const features = [
        {
            title: "Extensive Coding Practice",
            description: "Access 5,000+ problems in 23 languages, from beginner to advanced",
        },
        {
            title: "AI-Powered Guidance",
            description: "Personalized support through an AI chatbot for every learner",
        },
        {
            title: "Seamless Accessibility",
            description: "Cloud-based platform, accessible on any device without installation",
        },
        {
            title: "Regional Language Support",
            description: "Learn coding in multiple regional languages for wider reach",
        },
        {
            title: "Engaging Gamified Learning",
            description: "Motivate students with points, badges, and leaderboards",
        },
        {
            title: "Industry-Ready Training",
            description: "Real-world projects and challenges for job and interview preparation",
        },
    ];


    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the animation
            easing: "ease-in-out", // Easing function
            once: true, // Animation happens only once when the element is visible
        });
    }, []);

    return (
        <div className="empower-app">
            <h1 className="empower-title" data-aos="fade-up">
                codeXodus Features

            </h1>
            <div className="empower-features-grid">
                {features.map((feature, index) => (
                    <div
                        className="empower-feature-card"
                        key={index}
                        data-aos="fade-up" // Adding AOS animation to each feature card
                    >
                        <h2>{feature.title}</h2>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
