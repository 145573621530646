// // import React from 'react';
// // import './HeroSection.css';
// // import backgroundImage from '../Images/hero.svg'; // Replace with the correct path

// // const HeroSection = () => {
// //     return (
// //         <div className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
// //             <div className="overlay">
// //                 <div className="content-container">
// //                     <div className="contentt">
// //                         <h2>Empowering Tomorrow's Innovators, One Line at a Time</h2>
// //                         <h1>&lt;codeXodus/&gt;</h1>
// //                         <p>
// //                             CodeXodus offers a comprehensive approach to coding education, covering everything
// //                             from foundational programming concepts to advanced problem-solving techniques.
// //                         </p>
// //                         <div className="buttonss">
// //                             <button className="btn primary">Start Your Free Trial</button>
// //                             <button className="btn secondary">Demo</button>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default HeroSection;


import React, { useEffect } from 'react';
import './HeroSection.css';
import backgroundImage from '../Images/hero.svg'; // Replace with the correct path
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const HeroSection = () => {
    // Initialize AOS on component mount
    useEffect(() => {
        AOS.init({
            duration: 1000, // animation duration
            easing: 'ease-in-out', // easing function
            once: true, // animation will only happen once when the element comes into view
        });
    }, []);

    const openCalendly = () => {
        window.open('https://calendly.com/help-codexodus/30min', '_blank');
    };

    const openTrial = () => {
        window.open('https://app.codexodus.com', '_blank');
    };

    return (
        <div className="hero-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="overlay">
                <div className="content-container">
                    <div className="contentt">
                        <h2 data-aos="fade-up">Are You Struggling to Attract or Retain Students to Your Coaching Business?
                        </h2>
                        <h1 data-aos="fade-up" data-aos-delay="200">&lt;codeXodus/&gt;</h1>
                        <p data-aos="fade-up" data-aos-delay="400">
                            Empower your learners with hands-on coding practice, AI-driven insights, and a platform tailored to your coaching needs.

                        </p>
                        <div className="buttonss">
                            <button
                                className="btn primary"
                                data-aos="fade-up"
                                data-aos-delay="600"
                                onClick={openTrial}
                            >
                                Get Started Now
                            </button>
                            <button
                                className="btn secondary"
                                data-aos="fade-up"
                                data-aos-delay="800"
                                onClick={openCalendly}
                            >

                                Partner with CodeXodus
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;




