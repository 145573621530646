import React from 'react';
import './CodeXodusOffers.css';

const offers = [
    {
        title: "Your Trusted Tech Partner",
        details: [
            "Add CodeXodus as a reliable technology partner to enhance your market credibility and attract students with an AI- driven, cutting - edge platform"
        ]
    },
    {
        title: "Upsell Premium Courses",
        details: [
            "Easily offer advanced courses and certifications to students, creating new opportunities for revenue and increase your market value"
        ]
    },
    {
        title: "Expand Your Audience",
        details: [
            "Reach non-English medium learners with regional language support, making coding education accessible and inclusive for all"
        ]
    },
    {
        title: "Monetize New Opportunities",
        details: [
            "Earn additional income through proctored exams, certifications and corporate training programs tailored to diverse audiences"
        ]
    }
];

const openCalendly = () => {
    window.open('https://calendly.com/help-codexodus/30min', '_blank');
};

const openTrial = () => {
    window.open('https://app.codexodus.com', '_blank');
};

const CodeXodusOffers = () => {
    return (
        <div className="codexodus-offers-container">
            <h1 className="main-title">What Not to Miss with codeXodus</h1>
            <h2 className="subtitle">OFFERS</h2>
            <div className="offers-grid">
                {offers.map((offer, index) => (
                    <div key={index} className="offer-card">
                        <h3 className="offer-title">{offer.title}</h3>
                        <ul className="offer-details">
                            {offer.details.map((detail, idx) => (
                                <li key={idx}>{detail}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className="cta-section">
                <p>Don’t wait to elevate your business—partner with us today and take the next big step!</p>
                <div className="cta-buttons">
                    <button className="get-started" onClick={openTrial}>Get started</button>
                    <button className="talk-to-sales" onClick={openCalendly}>Talk to sales</button>
                </div>
            </div>
        </div>
    );
};

export default CodeXodusOffers;
