// import React from 'react';
// import './AvailableCourses.css';
// import Image1 from '../Images/C.svg';
// import Image2 from '../Images/cplusplus.svg';
// import Image3 from '../Images/Java.svg';
// import Image4 from '../Images/Python.svg';
// import Image5 from '../Images/DAA.svg';
// import Image6 from '../Images/OOPS.svg';
// import Image7 from '../Images/Csharp.svg';
// import Image8 from '../Images/DataStructures.svg';


// const courses = [
//     { title: "C Programming", image: Image1 },
//     { title: "C++ Programming", image: Image2 },
//     { title: "Java Programming", image: Image3 },
//     { title: "Python Programming", image: Image4 },
//     { title: "Data Structures", image: Image1, badge: "Best Courses" },
//     { title: "Design & Analysis of Algorithms", image: Image8, badge: "Best Courses" },
//     { title: "Object-Oriented Programming", image: Image6, badge: "Best Courses" },
//     { title: "C# Programming", image: Image7 },
// ];

// const AvailableCourses = () => {
//     return (
//         <div className="available-courses-container">
//             <h1 className="title">Available courses</h1>
//             <div className="course-grid">
//                 {courses.map((course, index) => (
//                     <div key={index} className="course-card">
//                         {course.badge && <div className="badge">{course.badge}</div>}
//                         <img src={course.image} alt={course.title} className="course-image" />
//                         <h2 className="course-title">{course.title}</h2>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default AvailableCourses;

import React, { useEffect } from 'react';
import './AvailableCourses.css';
import Image1 from '../Images/C.svg';
import Image2 from '../Images/cplusplus.svg';
import Image3 from '../Images/Java.svg';
import Image4 from '../Images/Python.svg';
import Image5 from '../Images/DAA.svg';
import Image6 from '../Images/OOPS.svg';
import Image7 from '../Images/Csharp.svg';
import Image8 from '../Images/DataStructures.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const courses = [
    { title: "C Programming", image: Image1 },
    { title: "C++ Programming", image: Image2 },
    { title: "Java Programming", image: Image3 },
    { title: "Python Programming", image: Image4 },
    { title: "Data Structures", image: Image1, badge: "Best Courses" },
    { title: "Design & Analysis of Algorithms", image: Image8, badge: "Best Courses" },
    { title: "Object-Oriented Programming", image: Image6, badge: "Best Courses" },
    { title: "C# Programming", image: Image7 },
];

const AvailableCourses = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of the animation
            easing: 'ease-in-out', // Easing function for animation
            once: true, // Trigger animation only once
        });
    }, []);

    return (
        <div className="available-courses-container">
            <h1 className="title" data-aos="fade-up">Available Courses</h1>
            <div className="course-grid">
                {courses.map((course, index) => (
                    <div key={index} className="course-card" data-aos="fade-up" data-aos-delay={`${index * 100}`}>
                        {course.badge && <div className="badge">{course.badge}</div>}
                        <img src={course.image} alt={course.title} className="course-image" />
                        <h2 className="course-title">{course.title}</h2>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AvailableCourses;
