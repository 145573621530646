// import React from 'react';
// import './Code.css';
// import Image1 from '../Images/c1.svg';
// import Image2 from '../Images/c2.svg';

// function App() {
//     return (
//         <div className="code-container">
//             <div className="code-card">
//                 <div className="code-image-container">
//                     <img src={Image1} alt="Code Vision" className="code-image" />
//                 </div>
//                 <div className="code-text-container code-vision">
//                     <h2 className="code-heading">Vision</h2>
//                     <p className="code-paragraph">
//                         Our mission is to empower students with the essential coding skills
//                         needed for success in the modern tech landscape.
//                     </p>
//                 </div>
//             </div>
//             <div className="code-card">
//                 <div className="code-image-container">
//                     <img src={Image2} alt="Code Mission" className="code-image" />
//                 </div>
//                 <div className="code-text-container code-mission">
//                     <h2 className="code-heading">Mission</h2>
//                     <p className="code-paragraph">
//                         We envision bridging the gap between academic learning and industry requirements,
//                         providing an engaging and interactive learning experience that prepares students
//                         for real-world challenges.
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default App;

import React, { useEffect } from 'react';
import './Code.css';
import Image1 from '../Images/c1.svg';
import Image2 from '../Images/c2.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

function App() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            easing: 'ease-in-out', // Easing function
            once: true, // Animation triggers only once when element comes into view
        });
    }, []);

    return (
        <div className="code-container">
            <div className="code-card" data-aos="fade-up">
                <div className="code-image-container">
                    <img src={Image1} alt="Code Vision" className="code-image" />
                </div>
                <div className="code-text-container code-vision">
                    <h2 className="code-heading">Vision</h2>
                    <p className="code-paragraph">
                        To revolutionize programming education by empowering institutions, instructors, and learners with cutting-edge technology, fostering a generation of skilled coders ready to tackle real-world challenges.

                    </p>
                </div>
            </div>
            <div className="code-card" data-aos="fade-up" data-aos-delay="200">
                <div className="code-image-container">
                    <img src={Image2} alt="Code Mission" className="code-image" />
                </div>
                <div className="code-text-container code-mission">
                    <h2 className="code-heading">Mission</h2>
                    <p className="code-paragraph">
                        To offer an AI-driven coding platform supporting 23+ languages, delivering personalized learning, and bridging education with employability through scalable, impactful solutions.

                    </p>
                </div>
            </div>
        </div>
    );
}

export default App;
